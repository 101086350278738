import "./footer.css";
import thnlogo from "./components/thnigeria.svg";
import tw from "./components/Tw.svg";
import ig from "./components/Ig.svg";
import tik from "./components/tik.svg";
import DarkModeToggle from "./DarkModeToggle";
function Footer() {
  return (
    <>
      <div className="ft-a1">
        <div className="c-s-t-y">
          {" "}
          <p className="c-a-s">Charting your way to success</p>
          <p className="t-y-t">Take your trade to the next level</p>
        </div>
        <div className="l-e"></div>

        <div className="f-t-l-l-c">
          <a href="/">
            {" "}
            <img src={thnlogo} alt={"TradingView Hub Nigeria"} />
          </a>

          <div className="c-u-n">
            <DarkModeToggle />

            <a
              href="https://x.com/backtesttools/status/1738592585050894663?s=46"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tw} alt={"BacktestTools"} />
            </a>
            <a
              href="https://www.instagram.com/backtesttools?igsh=YXo4ZjZxeTlqc3Ru"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ig} alt={"BacktestTools"} />
            </a>
            <a
              href="https://www.tiktok.com/@backtesttools?_t=8oM0V85Azt0&_r=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tik} alt={"BacktestTools"} />
            </a>
          </div>
        </div>

        <label>
          <span className="c-o">©</span> 2024 TradingViewHub Nigeria.
        </label>
      </div>
    </>
  );
}

export default Footer;
